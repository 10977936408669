export const useFormatPrice = () => {
  const formatPrice = (price: number) => {
    const num = Number(price);

    if (num % 1 === 0) {
      return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(num);
    } else {
      return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num);
    }
  };

  return {
    formatPrice,
  };
};
